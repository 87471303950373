import React from "react"
import PropTypes from "prop-types"
import Images from "../image"
import "../../scss/title/5-big-title.scss"

const BigTitle = ({ filename, alt, text }) => (
  <>
    <h1 className={"big-title"}>
      <Images filename={filename} alt={alt}/>
    </h1>

    <p className={"big-title"}>
      {text}
    </p>
  </>
)

BigTitle.propTypes = {
  filename: PropTypes.string,
  alt: PropTypes.string,
  text: PropTypes.string,
}
BigTitle.defaultProps = {
  filename: ``,
  alt: ``,
  text: ``,
}

export default BigTitle

import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import BigTitle from "../components/title/5-bigTitle"
import SubTitle from "../components/title/6-subTitle"
import Brands from "../components/9-brands"
import Images from "../components/image"
import Layout from "../components/0-layout"
import Lists from "../components/8-lists"
import Points from "../components/7-points"
import Query from "../components/query"
import SEO from "../components/seo"

/**
 * @property {object} allWarehouseJson
 * @property {object} subTitle
 * @property {object} wareHouse
 * @property {object} webSystem
 */

const WarehousePage = () => {
  const data = Query()

  const wh = useStaticQuery(graphql`
      query WarehousQuery {
          allWarehouseJson {
              edges {
                  node {
                      subTitle {
                          fileNameSubTitle
                          altSubTitle
                          fileNameImg
                          altImg
                          text
                      }
                      points {
                          li
                          count
                          alt
                      }
                      wareHouse {
                          b
                          p
                      }
                      webSystem {
                          b
                          p
                      }
                  }
              }
          }
      }
  `)

  return (
    <Layout sitePath={"warehouse"} pageTitle={data.allFooterJson.edges[0].node.li}>

      <SEO title={data.site.siteMetadata.warehouse}/>

      <BigTitle
        filename={"warehouse/big-title.jpg"}
        alt={data.site.siteMetadata.patent}
        text={
          "「倉庫業の登録」が無いとできないタイヤ保管。" +
          "現状では登録倉庫での保管が少なく、コンプライアンス上は問題です。" +
          "ドライバー、カーディーラー、タイヤ・カー用品店、カーリース会社など、" +
          "多くのお客様が対応できる倉庫・サービスを求めています。"
        }
      />

      <SubTitle v={wh.allWarehouseJson.edges[0].node.subTitle}/>

      <Points v={wh.allWarehouseJson.edges[0].node.points}/>

      <div className={"img"}>
        <Images filename={"warehouse/web-system.jpg"}
                alt={data.site.siteMetadata.webSystem}/>
      </div>

      <div className={"lists"}>
        <Lists t={"現有倉庫（空倉庫）を活用"}
               v={wh.allWarehouseJson.edges[0].node.wareHouse}/>

        <Lists t={"安全・便利な Web 管理システム"}
               v={wh.allWarehouseJson.edges[0].node.webSystem}/>
      </div>

      <Brands/>

    </Layout>
  )
}

export default WarehousePage

import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import Images from "./image"
import "../scss/9-brands.scss"

/**
 * @property {object} allBrandsJson
 */

const Brands = () => {
  const logo = useStaticQuery(graphql`
      query BrandsQuery {
          allBrandsJson {
              edges {
                  node {
                      img
                  }
              }
          }
      }
  `)

  let items = []

  logo.allBrandsJson.edges.forEach(i =>
    items.push(
      <a href={"https://www.google.com/search?q=" + i.node.img}>
        <Images filename={"maker-logos/" + i.node.img + ".jpg"} alt={i.node.img}/>
      </a>,
    ),
  )

  return (
    <div className={"flex-start-warp"} id={"brands"}>
      {items}
    </div>
  )

}

export default Brands

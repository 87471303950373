import React from "react"
import PropTypes from "prop-types"
import Images from "../image"
import "../../scss/title/6-sub-title.scss"

/**
 * @property {object} fileNameSubTitle
 * @property {object} altSubTitle
 * @property {object} fileNameImg
 * @property {object} altImg
 */

const SubTitle = (v) => {
  let items = []

  v.v.forEach(i =>
    items.push(
      <article className={"sub-title"}>
        <h2 className={"sub-title-h2"}>
          <Images filename={i.fileNameSubTitle} alt={i.altSubTitle}/>
        </h2>

        <div className={"clearfix"}>
          <div className={"sub-title-image"}>
            <Images filename={i.fileNameImg} alt={i.altImg}/>
          </div>

          <p>{i.text}</p>
        </div>
      </article>,
    ),
  )

  return items
}

SubTitle.propTypes = { v: PropTypes.array }
SubTitle.defaultProps = { v: [] }

export default SubTitle

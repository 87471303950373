import React from "react"
import PropTypes from "prop-types"
import "../scss/7-points.scss"

const Points = (v) => {
  let items = []

  v.v.forEach(i =>
    items.push(
      <li className={"points-li"}>
        <h3 className={"points-li-h3"}>
          <div className={"title-gray"}>
            <span className={"count"}>
              Point&nbsp;<b>{i.count}</b>
            </span>

            <span className={"alt"}>
              {i.alt}
            </span>
          </div>
        </h3>

        {i.li}
      </li>,
    ),
  )

  return (
    <ul className={"points"}>
      {items}
    </ul>
  )
}

Points.propTypes = { v: PropTypes.array }
Points.defaultProps = { v: [] }

export default Points
